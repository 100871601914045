import React from 'react';
import './App.css';
import GeneradorMarkdown from './components/GeneradorMardown';

function App() {
  return (
    <div className="App">
    <GeneradorMarkdown />
    </div>
  );
}

export default App;
